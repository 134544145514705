import React from 'react'
import Portfolio from '../Portfolio'
import Div from '../Div'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Autoplay]);

export default function PortfolioSlider() {
  const portfolioData = [
    {
      title:'Colorful Art Work',
      subtitle:'See Details',
      src:'/images/portfolio_1.jpeg'
    },
    {
      title:'Colorful Art Work3',
      subtitle:'See Details',
      src:'/images/portfolio_3.jpeg'
    },
    {
      title:'Colorful Art Work2',
      subtitle:'See Details',
      src:'/images/portfolio_2.jpeg'
    },
    {
      title:'Colorful Art Work1',
      subtitle:'See Details',
      src:'/images/portfolio_3.jpeg'
    }
  ]


  const items = [
    <div className="item" data-value="1"><img src={'/images/portfolio_1.jpeg'} style={{pointerEvents: 'none', userSelect: 'none', borderRadius: 15, border: 'solid 3px #f5f5f5', width: '700px'}} alt='item 1' /></div>,
    <div className="item" data-value="2"><img src={'/images/portfolio_3.jpeg'} style={{pointerEvents: 'none', userSelect: 'none', borderRadius: 15, border: 'solid 3px #f5f5f5'}} alt='item 2' /></div>,
    <div className="item" data-value="3"><img src={'/images/portfolio_2.jpeg'} style={{pointerEvents: 'none', userSelect: 'none', borderRadius: 15, border: 'solid 3px #f5f5f5'}} alt='item 3' /></div>,
    <div className="item" data-value="4"><img src={'/images/portfolio_3.jpeg'} style={{pointerEvents: 'none', userSelect: 'none', borderRadius: 15, border: 'solid 3px #f5f5f5'}} alt='item 4' /></div>,
];

  
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };
  
  return (

    <Swiper
      autoplay={{ delay: 500 }}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1}
      navigation
      breakpoints={{
        576: {
          slidesPerView: 1,
          spaceBetween: 30
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40
        },
        992: {
          slidesPerView: 2,
          spaceBetween: 50
        }
      }}
    >
      <SwiperSlide><img src={'/images/portfolio/Ecosolux-1.png'} style={{pointerEvents: 'none', userSelect: 'none', borderRadius: 15, border: 'solid 3px #f5f5f5', width: '700px'}} alt='item 1' /></SwiperSlide>
      <SwiperSlide><img src={'/images/portfolio/Felipemotors-1.png'} style={{pointerEvents: 'none', userSelect: 'none', borderRadius: 15, border: 'solid 3px #f5f5f5'}} alt='item 2' /></SwiperSlide>
      <SwiperSlide><img src={'/images/portfolio/H-pack-1.png'} style={{pointerEvents: 'none', userSelect: 'none', borderRadius: 15, border: 'solid 3px #f5f5f5'}} alt='item 3' /></SwiperSlide>
      <SwiperSlide><img src={'/images/portfolio/Imovel-valoriza-1.png'} style={{pointerEvents: 'none', userSelect: 'none', borderRadius: 15, border: 'solid 3px #f5f5f5'}} alt='item 3' /></SwiperSlide>
      <SwiperSlide><img src={'/images/portfolio/Sat-1.png'} style={{pointerEvents: 'none', userSelect: 'none', borderRadius: 15, border: 'solid 3px #f5f5f5'}} alt='item 3' /></SwiperSlide>
      <SwiperSlide><img src={'/images/portfolio/Studio-orion_-1.png'} style={{pointerEvents: 'none', userSelect: 'none', borderRadius: 15, border: 'solid 3px #f5f5f5'}} alt='item 3' /></SwiperSlide>

    </Swiper>
  )
}
