import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { click } from '@testing-library/user-event/dist/click';

export default function Button({ btnLink, btnText, variant, icon, type, click }) {
  return (
    <>
    { type === 'button' ?
      <Link
      className={variant ? `cs-text_btn btn-ver-contato ${variant}` : 'cs-text_btn btn-ver-contato'}
      onClick={click}
      >
        <>
          <span>{btnText}</span>
          {icon ? icon : <Icon icon="bi:arrow-right" />}
        </>
      </Link>

    :
      <Link
        to={btnLink}
        className={variant ? `cs-text_btn ${variant}` : 'cs-text_btn'}
      >
        <>
          <span>{btnText}</span>
          {icon ? icon : <Icon icon="bi:arrow-right" />}
        </>
      </Link>
    }
    </>
  );
}
