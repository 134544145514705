import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {
  const copyrightLinks = [
    {
      title: 'Fale Conosco',
      href: '#contato'
    },
    {
      title: 'Política de Privacidade',
      href: '/politica-de-privacidade.pdf'
    }
  ]
  
  const serviceMenu = [
    {
      title: 'Landing Page e Sites Institucionais',
      href: '/service/service-details'
    },
    {
      title: 'UI/UX Design',
      href: '/service/service-details'
    },
    {
      title: 'Desenvolvimento Wordpress',
      href: '/service/service-details'
    },
    {
      title: 'Identidade Visual e Aplicações',
      href: '/service/service-details'
    },
    {
      title: 'Markenting Digital e Social Media',
      href: '/service/service-details'
    },
  ]

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget 
                  logoSrc='/images/logo.svg' 
                  logoAlt='Logo'
                  logoStyle={{width: '180px', height: 'auto'}}
                  textStyle={{fontWeight: '550', fontSize: '0.9rem',}}
                  text ='Chegamos para transformar o jeito de criar, inovar e inspirar. Acreditamos no potencial para transformar e mudar a tecnologia com marketing digital.'
                />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget styleList={{fontSize: '0.9rem', marginTop: '0.5rem', marginBottom: '0.5rem'}} menuItems={serviceMenu} menuHeading='Serviços'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget withIcon title='Vamos conversar?'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter 
                  title='Newsletter' 
                  subtitle='Fique por dentro de nossas novidades e promoções.' 
                  placeholder='seu@email.com'
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div style={{fontWeight: 600, fontSize: '0.8rem'}}>Copyright © 2018 - {new Date().getFullYear()} - Agência Upstairs - Marketing Digital e Desenvolvimento Tecnológico.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant='cs-style2' styleList={{fontWeight: 600, fontSize: '0.8rem'}}/>
          </Div>
        </Div>
      </Div>
    </footer>
  )
}
