import React from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({withIcon, title}) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          <a target='_blank' rel='noreferrer' href='tel:5511992020885'>(11) 9 9202 0885</a>
        </li>
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          <a target='_blank' rel='noreferrer' href='mailto:comercial@agenciaupstairs.com.br' style={{fontSize: '0.9rem'}}>comercial@agenciaupstairs.com.br</a>
        </li>
        {/*
        <li>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
          <a target='_blank' rel='noreferrer' href='https://www.google.com/maps/place/R.+Ana+Maria+Singer,+4+-+Jardim+Nossa+Sra.+de+Fatima,+S%C3%A3o+Bernardo+do+Campo+-+SP,+09820-300/data=!4m2!3m1!1s0x94ce4105b18769b3:0x837715c4af203cb7?sa=X&ved=2ahUKEwj0vP_A9-_9AhV_vJUCHQ40ALsQ8gF6BAgPEAI'>R. Ana Maria Singer, 4 <br/>São Bernardo do Campo - SP <br />09820-300</a>
        </li>*/
        }
      </ul>
    </>
  )
}
