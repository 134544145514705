import React from 'react'
import { Icon } from '@iconify/react';
import './pricing.scss'
import Button from '../Button';
import Div from '../Div';
import { BsStar, BsStarHalf, BsStarFill } from 'react-icons/bs';

const showContato = () => {
  document.getElementById('side-bar-head').classList.add('active');    
}

export default function PricingTable({title, price, currency, features, btnLink, btnText, introduction}) {
  return (
    <Div className="cs-pricing_table cs-style1">
      <h2 className="cs-pricing_title cs-white_color">
        {title === 'Starter' ? <BsStar style={{marginBottom: '1rem'}}/> : title === 'Expert' ? <BsStarHalf style={{marginBottom: '1rem'}}/> : title === 'Enterprise' ? <BsStarFill style={{marginBottom: '1rem'}}/> : null}
        <br />
        {title}
      </h2>
      <Div className="cs-pricing_info">
        <Div className="cs-price">
          <h3 className="cs-white_color" style={{fontSize: '1.9rem'}}>{currency}{price}</h3>          
        </Div>
        <Div className="cs-white_color" style={{fontWeight: 600, fontSize: '0.9rem'}}>{introduction}</Div>
      </Div>
      <ul className="cs-pricing_feature cs-mp0">
        {features.map((feature, index)=>(
          <li key={index}>
            <span className="cs-feature_icon cs-accent_color">
              <Icon icon="bi:arrow-right-circle" className='cs-white_color' />
            </span>
            <span className='cs-white_color'>{feature}</span>
          </li>
        ))}
      </ul>
      <Div className="cs-pricing_btn_wrap">
        <Button click={() => showContato()} btnText={btnText} type={'button'}/>
      </Div>
    </Div>
  )
}
