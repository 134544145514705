import React from 'react'
import parse from 'html-react-parser';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BsWhatsapp } from 'react-icons/bs';
import Spacing from '../Spacing'
import Div from '../Div';
import { Gtag_report_conversion } from '../Google/conversionClick';

export default function SectionHeading({title, subtitle, paragraph, btnLink, btnText, btnLinkS, btnTextS, variant, children}) {
  return (
    <Div className={variant ? `cs-section_heading ${variant}` : `cs-section_heading cs-style1`}>
      <h3 className="cs-section_subtitle" style={{color: '#7D56C0', backgroundColor: '#7D56C033', fontSize: '0.9rem',borderRadius: 30, padding: '0.5rem 0.8rem', display: 'inline-block'}}>{parse(subtitle)}</h3>
      <h2 className="cs-section_title">{parse(title)}</h2>
      {paragraph && ( 
      <p className='cm-p_1'>{paragraph}</p>
      )}
      {children}
      {btnText && (
        <>
          <Spacing lg='45' md='20'/>
          <a href={btnLink} className='cm-btn cs-semi_bold text-center' style={{width: '100%'}}> {btnText} &nbsp;<HiOutlineArrowNarrowRight /></a> <br />
          <a href={btnLinkS} target='_blank' onClick={() => Gtag_report_conversion()} className='cm-btn_wpp cs-semi_bold btn-whatsapp'><BsWhatsapp />&nbsp; {btnTextS}</a>
       
        </>
      )}
    </Div>
  )
}
