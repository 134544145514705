import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import PricingTableList from '../PricingTable/PricingTableList';
import TeamSlider from '../Slider/TeamSlider';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';

export default function Home() {

  const bgImageUrl = '/images/service_bg.png';
  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'LinkedIn',
      links: 'https://www.linkedin.com/company/ag%C3%AAncia-upstairs/about/',
    },
    {
      name: 'Facebook',
      links: 'https://www.facebook.com/profile.php?id=100063771084619',
    },
    {
      name: 'Instagram',
      links: 'https://www.instagram.com/agencia_upstairs',
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'Cliente Atendidos',
      factNumber: '32',
    },
    {
      title: 'Anos no Mercado',
      factNumber: '5',
    },
    {
      title: 'Conversão',
      factNumber: '91.05%',
    },
    {
      title: 'Gerenciados em Anúncios',
      factNumber: '487K',
    },
  ];


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="Sua empresa no degrau acima"
        subtitle="Chegamos para transformar o jeito de criar, inovar e inspirar. Acreditamos no potencial para
        transformar e mudar a tecnologia com marketing digital."
        btnText="Conheça nossos serviços"
        btnLink="#nossos-servicos"
        btnLinkWpp="https://wa.me//5511992020885?text=Tenho%20interesse%20em%20mais%20informações"
        btnTextWpp="Solicite um orçamento"
        scrollDownId="#service"
        socialLinksHeading="Siga-nos"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div style={{backgroundImage: `url(${bgImageUrl})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} id='conheca'>
        <div className="container">
          <FunFact
            variant="cs-type1"
            title="Conheça a Upstairs"
            subtitle="Atualidade é o nosso sobrenome. Estamos sempre evoluindo conforme as
            novas tecnologias do mercado digital, que muitas vezes é um tanto quanto
            desafiador."
            data={funfaceData}
          />
        </div>      
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="75" md="40" />
      <Div id="nossos-servicos" style={{paddingTop: '5rem', paddingBottom: '5rem',}} >
            <Div className="container">
              <Div className="row">
                <Div className="col-xl-5">
                  <SectionHeading
                    title="Como podemos ajudar"
                    subtitle="Serviços"
                    paragraph="Se você tem uma empresa, você certamente busca ampliar sua marca,
                    impactando mais pessoas. A internet é a melhor amiga que você pode ter para
                    alavancar as suas negociações e te colocar como ponto de referência.
                    Desenvolvemos todo o processo ao seu lado, e proporcionamos as condições
                    para que você usufrua da tecnologia que permite mostrar sua empresa para
                    mais pessoas."
                    btnText="Solicite seu orçamento"
                    btnLink="#contato"
                    btnTextS="Nos chame no WhatsApp"
                    btnLinkS="https://wa.me//5511992020885?text=Tenho%20interesse%20em%20mais%20informações"
                  />
                  <div className='cs-hidden_mobile'>
                    <Spacing lg='90' md='45'/>
                  </div>
                </Div>
                <Div className='col-xl-7'>
                <Spacing lg='90' md='45'/>
                  <Div className='row'>
                    <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                    <Div className='col-lg-3 col-sm-6'>
                      <Card
                        title='UI/UX design'
                        src='/images/servicos/ui_ux-1.png'
                        alt='Service'
                      />
                      <Spacing lg='0' md='30'/>
                    </Div>
                    <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                    <Div className='col-lg-3 col-sm-6'>
                      <Card
                        title='Sites'
                        src='/images/servicos/Desenvolvimento-1.jpg'
                        alt='Service'
                      />
                      <Spacing lg='0' md='30'/>
                    </Div>
                    <Div className='col-lg-3 col-sm-6'>
                      <Card
                        title='Marketing Digital'
                        src='/images/servicos/Digital-marketing-1.jpg'
                        alt='Service'
                      />
                      <Spacing lg='0' md='30'/>
                    </Div>
                    <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                    <Div className='col-lg-3 col-sm-6'>
                      <Card
                        title='App'
                        src='/images/servicos/App-1.jpg'
                        alt='Service'
                      />
                      <Spacing lg='0' md='30'/>
                    </Div>
                    <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                    <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                    <Div className='col-lg-3 col-sm-6'>
                      <Card
                        title='Brand'
                        src='/images/servicos/Brand-1.jpg'
                        alt='Service'
                      />
                      <Spacing lg='0' md='30'/>
                    </Div>
                    <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                    <Div className='col-lg-3 col-sm-6'>
                      <Card
                        title='Audiovisual'
                        src='/images/servicos/Audiovisual-1.png'
                        alt='Service'
                      />
                    </Div>
                  </Div>
                </Div>
              </Div>
            </Div>
      </Div>
      {/* End Service Section */}
      </div>

      {/* Start Portfolio Section */}
      <Spacing lg="75" md="40" />
      <Div>
        <Div className="container" id='portfolio'>
          <SectionHeading
            title="Projetos de impacto"
            subtitle="Nosso portfólio"
            variant="cs-style1 text-center"
          />
          <Spacing lg="65" md="35" />
        </Div>
        <PortfolioSlider />
        <Spacing lg="65" md="35" />
        <Spacing lg="75" md="40" />

      </Div>
      {/* End Portfolio Section */}

      {/* Start Testimonial Section */}
      <div style={{backgroundImage: 'url(/images/background_testemunho.png)', backgroundPosition: 'center center', backgroundSize: 'cover'}}>
        <Div className="container">
          <Spacing lg="45" md="22" />
          <SectionHeading
            title="Veja o que nossos clientes dizem sobre nós"
            subtitle="Testemunhos"            
            variant="cs-style1 text-center"
          />
          <Spacing lg="45" md="22" />
          <div className='row justify-content-center'>
            <div className='col text-center'> 
              <iframe width="100%" className='youtube-iframe' height="420" style={{borderRadius: '30px'}} src="https://www.youtube.com/embed/sDznemUQYGM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>
          <Spacing lg="45" md="22" />
        </Div>
      </div>
      {/* End Testimonial Section */}

      {/* Start MovingText Section */}
      <Spacing lg="75" md="35" />
      <MovingText text="Sua empresa no degrau acima" />
      <Spacing lg="75" md="35" />
      {/* End MovingText Section */}
      
      {/* Start Pricing Section */}
      <Spacing lg="80" md="40" />
      <Div className="container" id=''>
        <SectionHeading
          title="Planos e preços <br/>únicos e imbatíveis"
          subtitle="Planos e Preços"
        />
        <Spacing lg="85" md="40" />
        <PricingTableList />
      </Div>
      <Spacing lg="80" md="40" />
      {/* End Pricing Section */}

      {/* Start CTA Section */}
      <Div className="container" id='contato'>
        <Cta
          title="Conte seu <i>projeto</i> para nós <br />queremos desenvolvê-lo <i>junto</i> de você"
        />
      </Div>
      {/* End CTA Section */}

    </>
  );
}
