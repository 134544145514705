import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget({styleDiv, styleLi}) {
  return (
        <Div className="cs-social_btns cs-style1">
          <Link to='https://www.linkedin.com/company/ag%C3%AAncia-upstairs/about/' className="cs-center">
            <Icon icon="fa6-brands:linkedin-in" />
          </Link>
          <Link to='https://www.facebook.com/profile.php?id=100063771084619' className="cs-center">
            <Icon icon="fa6-brands:facebook" />               
          </Link>
          <Link to='https://www.instagram.com/agencia_upstairs/' className="cs-center">
            <Icon icon="fa6-brands:instagram" />              
          </Link>
        </Div>
  )
}
