import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SocialWidget from '../Widget/SocialWidget';
import './header.scss';
import Div from '../Div';
import { BiPhoneOutgoing } from 'react-icons/bi';
import { BsWhatsapp } from 'react-icons/bs';
import { MdOutgoingMail } from 'react-icons/md';
import { Gtag_report_conversion } from '../Google/conversionClick';

export default function Header({ variant }) {
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  useEffect(() => {
    //Deixar confiruração abaixo ativa para a navbar ficar sempre ativa
    setIsSticky(true);

    //efeito sticky na navbar
    /*
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });*/
  }, []);

  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ''
        } cs-sticky_header ${isSticky ? 'cs-sticky_header_active' : ''}`}
      >
        <Div className="cs-main_header">
          <Div className="container">
            <Div className="cs-main_header_in">
              <Div className="cs-main_header_left">
                <Link className="cs-site_branding" to="/">
                  <img src="/images/logo.svg" width={'200px'} height={'auto'} alt="Logo" />
                </Link>
              </Div>
              <Div className="cs-main_header_center">
                <Div className="cs-nav cs-primary_font cs-semi_bold">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? 'block' : 'none'}`, textTransform: 'capitalize', fontWeight: 600 }}
                  >
                    <li>
                      <a href="/" onClick={() => setMobileToggle(false)}>
                        Início
                      </a>
                    </li>
                    <li>
                      <a
                        href="#conheca"
                        onClick={() => setMobileToggle(false)}
                      >
                        Conheça a Upstairs
                      </a>
                    </li>
                    <li>
                      <a
                        href='#nossos-servicos'
                        onClick={() => setMobileToggle(false)}
                      >
                        Serviços
                      </a>
                    </li>
                    <li>
                      <a
                        href='#portfolio'
                        onClick={() => setMobileToggle(false)}
                      >
                        Nossos Clientes
                      </a>
                    </li>
                    <li>
                      <a
                        href="#contato"
                        onClick={() => setMobileToggle(false)}
                      >
                        Contato
                      </a>
                    </li>

                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? 'cs-munu_toggle cs-toggle_active'
                        : 'cs-munu_toggle'
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </Div>
              </Div>
              <Div className="cs-main_header_right">
                <Div className="cs-toolbox">
                  <span
                    className="cs-icon_btn"
                    onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                  >
                    <span className="cs-icon_btn_in">
                      <span />
                      <span />
                      <span />
                      <span />
                    </span>
                  </span>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </header>

      <Div
        id='side-bar-head'
        className={
          sideHeaderToggle ? 'cs-side_header active' : 'cs-side_header'
        }
      >
        <button
          className="cs-close"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div
          className="cs-side_header_overlay"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <Div className="cs-side_header_in container">
          <Link className="cs-site_branding" to="/">
            <img src="/images/logo.svg" style={{marginBottom: '1rem'}} width={'250px'} height={'auto'} alt="Logo" />
          </Link>
          <Div className="cs-side_header_box">
            <h2 className="cs-side_header_heading" style={{color: '#f5f5f5'}}>
            Conte seu projeto para nós queremos desenvolvê-lo juntos de você
            </h2>
          </Div>
          <Div>
            <a href='https://wa.me//5511992020885?text=Tenho%20interesse%20em%20mais%20informações' onClick={() => Gtag_report_conversion()} target='_blank' rel='noreferrer' className='cm-btn_wpp btn-whatsapp' style={{marginTop: '1rem'}}><BsWhatsapp style={{fontSize: '1.5rem', marginRight: '0.4rem'}} /> (11) 9 9202 0885</a>
            <a href='tel:5511992020885' target='_blank' onClick={() => Gtag_report_conversion()} rel='noreferrer' className='cm-btn_transparent btn-contato' style={{marginTop: '1rem'}}><BiPhoneOutgoing style={{fontSize: '1.5rem', marginRight: '0.4rem'}} /> (11) 9 9202 0885</a>
            <a href='mailto:comercial@agenciaupstairs.com.br' target='_blank' onClick={() => Gtag_report_conversion()} rel='noreferrer' className='cm-btn_transparent btn-contato' style={{marginTop: '1rem'}}><MdOutgoingMail style={{fontSize: '1.5rem', marginRight: '0.4rem'}} /> comercial@agenciaupstairs.com.br</a>
          </Div>
          <Div className="cs-side_header_box">
            <div style={{display: 'flex', justifyContent: 'center', paddingTop: '2rem'}}>
              <SocialWidget />
            </div>
          </Div>
        </Div>
      </Div>
    </>
  );
}
