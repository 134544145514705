
const dataLayer = (window.dataLayer = window.dataLayer || []);

function gtag(){ 
  dataLayer.push(arguments);
}

function Gtag_report_conversion(url) {
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  gtag('event', 'conversion', {
      'send_to': 'AW-408698076/MP6hCPH1kpsYENz58MIB',
      'event_callback': callback
  });
  return false;
}

export { Gtag_report_conversion }