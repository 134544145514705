import React from 'react';
import parse from 'html-react-parser';
import './hero.scss';
import Div from '../Div';
import VerticalLinks from '../VerticalLinks';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { BsWhatsapp } from 'react-icons/bs';
import { RxCode } from 'react-icons/rx';
import { Gtag_report_conversion } from '../Google/conversionClick';


export default function Hero({
  title,
  subtitle,
  btnText,
  btnLink,
  btnLinkWpp,
  btnTextWpp,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
  bgImageUrl,
}) {
  return (
    <Div
      className="cs-hero cs-style1 cs-bg cs-fixed_bg cs-shape_wrap_1"
      style={{ backgroundImage: `url(${bgImageUrl})` }}
    >
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Div className="cs-hero_text">
          <h1 className="cs-hero_title" style={{color: '#7D56C0'}}>{parse(title)}</h1>
          <p className="cs-hero_subtitle" style={{ marginBottom: '3rem' }}>{subtitle}</p>
          <Div className="cs-hero_info">
            <Div>
              <a href={btnLink} className='cm-btn cs-semi_bold text-center btn-hero btn-servicos' style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}> {btnText} &nbsp;<RxCode /></a>
              <a href={btnLinkWpp} onClick={() => Gtag_report_conversion()} target='_blank' className='cm-btn cs-semi_bold text-center btn-whatsapp' style={{backgroundColor: '#25D366', marginLeft: '0.5rem', marginRight: '0.5rem'}}><BsWhatsapp /> &nbsp;{btnTextWpp}</a> <br />
            </Div>
          </Div>
        </Div>
      </Div>
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </Div>
  );
}
